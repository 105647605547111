/* input {
  width: 100%;
  height: 100px;
  padding: 1rem;
  font-size: 1rem;
  border: none;
  box-sizing: border-box;
} */

.simple-keyboard .hg-button {
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  padding: 2.5rem 2rem !important; /* Padding */
  margin: 5px; /* Margin */
  font-weight: bold;
  font-size: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.simple-keyboard {
  padding-bottom: 0.75rem !important;
}

.kyboard {
  position: fixed;
  max-width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10000;

  background-color: #ececec;
  padding: 1rem;
  padding-top: 0;
  /* animation: slideInFromBottom 0.3s ease-out; */
}

.close-button {
  font-size: 3rem;
}

/* .not-show {
  display: hidden;
} */
/* .show {
  display: block;
  
} */
/* @keyframes slideInFromBottom {
  0% {
    transform: translate(-50%, 100%);

    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);

    opacity: 1;
  }
} */

@media (max-width: 800px) {
  .simple-keyboard .hg-button {
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    padding: 0.5rem 0.5rem !important; /* Padding */
    margin: 2px; /* Margin */
    font-weight: normal;
    font-size: 0.5rem;
  }
}
